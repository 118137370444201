import React from "react";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

import PageTitle from "../components/pageTitle";

import { about, bio, socialIcons, pic } from "./about.module.scss";

export default function About() {
  return (
    <>
      <Helmet>
        <title>Fresh Brewed Design - About</title>
      </Helmet>
      <PageTitle>About</PageTitle>
      <div className={about}>
        <div className={bio}>
          <h2>Jeremy Dunsworth</h2>
          <h3>Internet Product Creator</h3>
          <p>
            I started Fresh Brewed Design because I love helping people create
            products.
          </p>
          <p>
            I'm a creative problem solver with over a decade of experience
            designing, building, testing, and collaborating on products.
          </p>
          <p>No matter where you are in the process, I will help you:</p>
          <ol>
            <li>Reframe the problem you&apos;re solving</li>
            <li>Make sure there&apos;s a market</li>
            <li>Design, test, and iterate on solutions</li>
            <li>Quickly launch your product</li>
          </ol>
          <p>
            <Link to="/contact">Let&apos;s get started!</Link>
          </p>
          <p className={socialIcons}>
            <a href="https://www.linkedin.com/in/jeremydesigns/">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
              >
                <defs />
                <style>
                  {`@media (prefers-color-scheme:dark){path{fill:#efefef}}`}
                </style>
                <path d="M19 0H5a5 5 0 00-5 5v14a5 5 0 005 5h14a5 5 0 005-5V5a5 5 0 00-5-5zM8 19H5V8h3v11zM6.5 6.7c-1 0-1.8-.8-1.8-1.7s.8-1.8 1.8-1.8S8.3 4 8.3 5s-.8 1.7-1.8 1.7zM20 19h-3v-5.6c0-3.4-4-3.1-4 0V19h-3V8h3v1.8c1.4-2.6 7-2.8 7 2.4V19z" />
              </svg>
            </a>
          </p>
        </div>
        <StaticImage
          className={pic}
          src="../images/me.jpg"
          alt="Jeremy Dunsworth"
          layout="constrained"
          placeholder="tracedSVG"
          width={500}
        />
      </div>
    </>
  );
}
